import AnalysisCard from "../ToolsCard.vue";
import NeoLoader from "@/components/loader";
import NeoInput from "@/components/input";
import NeoButton from "@/components/button";
import VueMultiselect from "@/components/vue-multiselect";
// import "vue-multiselect/dist/vue-multiselect.min.css";
import "@/assets/css/multiselect.scss";
import {mapGetters, mapMutations, mapActions} from "vuex";
import CryptoJS from "crypto-js";
import {setUsernamesDropdownData, convertToCSV, csvFileName} from "../../../../utils/functions";
import OsintAxios from "@/axios/osint.js";
import JsonCSV from "vue-json-csv";

export default {
    name: "email-search",
    components: {AnalysisCard, NeoLoader, NeoInput, NeoButton, VueMultiselect, "download-csv": JsonCSV},
    props: {
        toolData: {
            type: Array,
            default: () => [],
        },
        tool: {
            type: String,
            default: "",
        },
        toolSearchQueries: Array,
    },
    data() {
        return {
            case_id: "",
            valueInput: "",
            allData: [],
            isLoading: false,
            allPhotos: [],
            expanded: false,
            photosLoading: false,
            dataLoaded: false,
            selectedItem: null,
            searchItem: "",
            flickrUsers: [],
            csvData: [],
            showResult: false,
            selectVal: "",
            request_id: "",
            holeheSearchResults: [],
            holeheResults: [],
        };
    },
    computed: {
        ...mapGetters(["getReadOnlyMode", "getResearchData", "getAllToolsData", "getCaseEmails", "getSocialPlatforms"]),

        fetchAllData() {
            if (this.getResearchData.personalDetails?.length || this.getResearchData.socialProfiles?.length) {
                this.getToolData();
            }
        },
        getMD5() {
            return CryptoJS.MD5(this.searchItem.toLowerCase()).toString();
        },
        getHoleResultsCount() {
            if (this.holeheSearchResults?.length) {
                const searchResults = this.holeheSearchResults.filter((el) => el.exists === true);
                if (searchResults?.length && searchResults?.length < 10) return String(searchResults.length).padStart(2, "0");
                else if (searchResults.length === 0) return 0;
                else return searchResults.length;
            }
        },
    },
    async mounted() {
        this.case_id = this.$route.params.id;
        const dataRes = this.getAllToolsData?.find((n) => n["tool-name"] === "email-search-tool");
        if (dataRes) {
            Object.entries(dataRes).forEach(([key, value]) => {
                this[key] = value;
            });
            this.redirectToQuery(dataRes);
        }
        this.getToolData();
    },
    beforeDestroy() {
        this.SET_ALL_TOOLS_DATA({
            ...this._data,
            "tool-name": "email-search-tool",
        });
    },
    watch: {
        // allData(newVal, prevVal) {
        //     if (newVal.length > 0) {
        //         this.$emit("searchResult", { data: newVal, source: "email-search", searchType: "email" });
        //     }
        // },
        // $route(newRoute, prevRoute) {
        //     if (newRoute.params.toolName === "flickr-tool") {
        //         if (newRoute.query.q !== this.valueInput) this.redirectToQuery();
        //     }
        // },
        "$route.query.q"(to, from) {
            if (to !== from && to !== this.valueInput.trim()) {
                this.redirectToQuery();
            }
        },

        // selectedItem() {
        //     this.showResult = false;
        // },
    },

    methods: {
        ...mapMutations(["SET_ALL_TOOLS_DATA"]),
        ...mapActions(["getAllCaseEmails"]),
        getPlatformIcon(platform) {
            let social = this.getSocialPlatforms.find((social) => social.key == platform);
            if (social) return social.icon_data;
            else {
                return require(`@/assets/icons/${platform}.svg`);
            }
        },

        async getToolData() {
            this.flickrUsers = await this.getDropdownData("email");
        },

        async getDropdownData(field) {
            let data = this.toolData.filter((user) => user.val_type === field);
            return data;
        },

        getOptionsList(list) {
            return [
                {
                    group: "Email",
                    categories: this[list],
                },
            ];
        },

        handleChecked(data) {
            let allChecked = false;
            this.$emit("updateToolData", "email-search", [...this.flickrUsers], data);
            allChecked = [...this.flickrUsers]?.length ? ([...this.flickrUsers].findIndex((n) => !n.visited) > -1 ? false : true) : false;

            this.$emit("handleChecked", allChecked);
        },

        convertToCSV(arr) {
            return convertToCSV(arr);
        },

        csvFileName(query, section) {
            return csvFileName(this.tool, query, section);
        },

        setInput() {
            this.searchType = "email";
            this.valueInput = this.selectedItem.value.trim();
        },

        setTheCustomInput(value) {
            this.searchType = "email";
            this.valueInput = value.trim();
            this.selectedItem = {
                value: value,
            };
        },

        async handleSearch() {
            this.isLoading = true;
            this.showResult = false;
            if (this.valueInput) {
                // this.$emit("searched", { data: { email: this.valueInput }, source: "email-search" });
                this.searchItem = this.valueInput.trim();
                const {data: sourcesCall} = await OsintAxios.post(
                    "/intel/search/sources",
                    {query: this.valueInput.trim()},
                    {
                        headers: {
                            "x-tool-name": this.$route.params.toolName,
                            "x-visited": true,
                        },
                    }
                );
                this.request_id = sourcesCall.request_id;
                // const response = await OsintAxios.post(
                //     "/tools/query",
                //     { query: this.valueInput },
                //     {
                //         headers: {
                //             "x-tool-name": this.$route.params.toolName,
                //             "x-visited": true,
                //         },
                //     }
                // );
                // this.request_id = response?.data?.query_id;
                //
                this.redirectTo();
                await this.getHoleheData();
                await this.setSearchedUser(this.valueInput);
                this.$forceUpdate();
                this.isLoading = false;
                this.showResult = true;
            } else {
                this.$toast.error("Please enter a valid input.");
                this.isLoading = false;
                this.showResult = true;
            }
        },

        async getHoleheData() {
            this.holeheSearchResults = [];
            this.holeheResults = [];
            const response = await OsintAxios.post(
                "/intel/holehe_search",
                {query: this.valueInput.trim()},
                {
                    headers: {
                        "x-tool-name": this.$route.params.toolName,
                        "x-visited": true,
                        "x-query-id": this.request_id,
                    },
                }
            );
            this.holeheSearchResults = response.data?.result;
            if (this.holeheSearchResults) {
                const holeheExists = this.holeheSearchResults?.filter((el) => el.exists === true);
                const holeheNotExists = this.holeheSearchResults?.filter((el) => el.exists === false);
                this.holeheResults = [...this.holeheResults, ...holeheExists, ...holeheNotExists];
            }
        },

        async setSearchedUser(searchKeyword) {
            const data = {
                value: searchKeyword,
                platform: "email-search",
                val_type: this.selectedItem?.val_type || "email",
                visited: true,
            };
            let url = `research-methodology/${this.case_id}/tools/profiles`;
            let isSuccess = await setUsernamesDropdownData(url, data);
            // if (isSuccess) {
            let dataItem = this[`flickrUsers`].find((dataItem) => {
                return dataItem.value === searchKeyword;
            });
            if (dataItem) dataItem["visited"] = true;
            else if (data?.value) {
                this[`flickrUsers`].push(data);
                this.$emit("addCustomData", data);
            }
            this.handleChecked(data);
            // }
        },

        redirectTo() {
            let query = {};
            if (this.request_id)
                query = {
                    query_id: this.request_id,
                };
            this.$router.push({path: this.$route.path, query}).catch((err) => {});
        },

        redirectToQuery(dataRes) {
            //redirect to same query
            if (this.$route?.query?.query_id) {
                let {query_id} = this.$route.query;
                let selected_query = this.toolSearchQueries?.find((el) => el._id === query_id);

                const value = Object.values(selected_query?.query)[0];
                this.searchKeyword = value;
                this.valueInput = value.trim();
                this.selectedItem = {
                    value: value,
                };
                this.selectVal = value;
                if (!dataRes) this.handleSearch();
            } else {
                this.redirectTo();
            }
        },
        onSelect({value}) {
            this.selectVal = value;
            this.$refs["analysis-tool-input"].search = value;
        },

        close() {
            // this.searchKeyword = this.selectVal;
            this.selectedItem = {
                value: this.selectVal,
            };
            this.searchType = "email";
            this.valueInput = this.selectVal.trim();
            this.$refs["analysis-tool-input"].search = this.selectVal;
        },
        handleChange(event) {
            if (event != this.selectVal) {
                this.showResult = false;
                this.searchClicked = false;
            }
            this.selectVal = event;
        },
    },
};
